import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import Alert from '../../components/Alert/Alert';

const ResearchEducationPage = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		query {
			groupImg: file(relativePath: { eq: "researchandeducation.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout
			header={intl.formatMessage({ id: 'nav.research' })}
			breadcrumb={{
				text: <FormattedMessage id='nav.about' />,
				path: '/about/'
			}}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.aboutDescription'
				})}
				lang={intl.locale}
				title={intl.formatMessage({ id: 'nav.research' })}
			/>
			<Container>
				{intl.locale === 'fr' && (
					<Row align='stretch'>
						<Col>
							<Alert header='Attention'>
								<p lang='fr'>
									Pour les services en français, veuillez contacter le Service des licences et à la
									clientèle de l’Office de réglementation de la construction des logements au
									416-487-HCRA (4272) ou <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
								</p>
							</Alert>
						</Col>
					</Row>
				)}

				<Row>
					<Col>
						<p style={{ marginTop: `0` }}>
							The HCRA has committed to fulfilling its legislative mandate and strategic goal to forecast
							and act on emerging issues affecting new homebuyers and builder qualifications through
							leading research and education.
						</p>
						<p>
							The Building Research & Collaboration Council is a partnership platform facilitated by the
							HCRA to advance its Research & Education Program. This program is committed to supporting
							the educational needs of builders and homeowners and promoting best practices in home
							construction through an evidence-based, cost-effective, and accessible approach. This
							mandate will be achieved, in part, by launching a collaborative forum that will encompass
							two streams:
						</p>
						<ol className='largeNums'>
							<li>
								<div>
									<b>Technical Research & Education:</b> in partnership with stakeholders, research
									and develop educational educational resources that prepare builders for upcoming
									technical trends and best practices in home construction.
								</div>
							</li>

							<li>
								<div>
									<b>Consumer Education:</b> in cooperation with consumers and consumer organizations,
									develop, publicize, and promote educational resources that support consumer
									protection, education, and awareness regarding homebuying and homeownership.
								</div>
							</li>
						</ol>
						<h2>Deliverables</h2>
						<ul>
							<li>
								Perform gap analysis to identify existing resources and any areas for new technical
								research and educational resources
							</li>
							<li>
								Engage in technical research, develop, and then disseminate practical resources through
								publications, case studies, videos, and webinars
							</li>
							<li>
								Provide guidance in anticipating emerging training and education needs with a view to
								establishing a proactive approach to the development, delivery, and dissemination of
								complementary materials
							</li>
							<li>
								Supporting the development of a public, web-based library of resources for
								builders/homeowners on the HCRA’s website
							</li>
							<li>
								Develop home maintenance guides, webinars, and resources for consumers, to support the
								homebuying and homeownership journeys
							</li>
							<li>
								Explore opportunities to partner on the development of research and education
								initiatives, through joint funding, co-authorship or providing technical expertise
								in-kin
							</li>
						</ul>
						<h2>Learn more</h2>
						<p>
							For information or questions, contact the HCRA’s Chief Research Officer, Siloni Waraich, at{' '}
							<a href='mailto:siloni.waraich@hcraontario.ca'>siloni.waraich@hcraontario.ca</a>.
						</p>
						<h2>Resource Hub</h2>
						<p>
							The HCRA has created a <b>Resource Hub</b> dedicated to providing educational resources and
							information on current and emerging trends, including requirements and updates to the
							Ontario Building Code. To visit the Hub, click{' '}
							<Link to='/about/research-and-education-program/research-education-resource-hub/'>
								here
							</Link>
							.
						</p>
						<p>
							<i>Stay tuned for added resources as this page is updated.</i>
						</p>
						<div style={{ maxWidth: `1000px`, margin: `2rem auto` }}>
							<Img fluid={data.groupImg.childImageSharp.fluid} alt='' style={{ marginBottom: `2rem` }} />
						</div>
						<p style={{ textAlign: `center` }}>
							<i>Launch of the Building Research & Collaboration Council, January 2024</i>
						</p>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default ResearchEducationPage;
